import Model from './model.js';

class Client extends Model {
  id = 0;
  name = '';
  // regions = [];
  // countries = [];
  isActive = true;
  hasApiAccess = true;

  mapForRequest() {
    return {
      name: this.name,
      isActive: this.isActive,
    };
  }
}

export default Client;
